
import Vue from "vue";

export default Vue.extend({
  name: "SearchResult",
  props: ["user"],
  methods: {
    getCategoryType(key: string): any {
      // @ts-ignore
      if (this.user.category_types[key]) {
        // @ts-ignore
        return this.user.category_types[key].join(", ");
      }
    }
  }
});
